import { CircularProgress, Fab, Typography } from '@mui/material';
import { Box, styled, useTheme } from '@mui/system';
import React, { useEffect, useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { Add } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import EventCard from '../../components/EventCard';
import axiosInstance from '../../axiosConfig';
import { formateDate, getMonthBounds } from '../../help';

const CalendarCustom = styled(Calendar)(({ theme }) => `
  box-shadow: 0px 1px 6px 0px rgba(26, 42, 99, 0.17);
  border-radius: 8px;
  border: none !important;
  width: 100%;
  max-width: 850px;

  .react-calendar__navigation {
    margin-bottom: 2px !important;
  }

  .react-calendar__viewContainer {
    margin-bottom: 4px !important;
  }

  .react-calendar__tile--active {
    background: ${theme.palette.secondary.main_200} !important;
  }

  .react-calendar__tile--now {
    background: ${theme.palette.secondary.main_900} !important;
  }

  .react-calendar__tile {
    padding: 5px 2.5px !important;
  }
`);

const BoxCustom = styled(Box)(({ theme }) => `
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${theme.spacing(2)};

  height: calc(100vh - 100px);
  
  ${theme.breakpoints.down('md')} {
    height: calc(100vh - 170px);
  }

  .overflow-part {
    height: 100%;
    overflow-y: auto;
  }
`);

const FabCustom = styled(Fab)(({ theme }) => `
  position: absolute;
  bottom: 16px;
  right: 16px;
  
  ${theme.breakpoints.down('md')} {
    bottom: 90px;
  }
`);

function Event() {
  const theme = useTheme();
  const navigate = useNavigate();
  const [value, onChange] = useState(new Date());
  const [data, setData] = useState({
    isLoading: true,
    data: [],
  });
  const [actualDay, setActualDay] = useState('');

  const onActiveStartDateChange = ({
    action, activeStartDate, value, view,
  }) => {
    onChange(activeStartDate);
  };

  const orderByDate = data => {
    const groupedByDate = {};

    data.forEach(event => {
      const eventDate = event.date.split('T')[0];

      if (!groupedByDate[eventDate]) {
        groupedByDate[eventDate] = [];
      }

      groupedByDate[eventDate].push(event);
    });

    // Convertir en format de liste souhaité
    const resultList = Object.keys(groupedByDate).map(date => ({ todayDate: date, events: groupedByDate[date] }));

    return resultList;
  };

  const scrollToDate = date => {
    const localDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
    const dateString = localDate.toISOString().split('T')[0].replace(/-/g, '');
    const element = document.getElementById(`date-${dateString}`);

    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  useEffect(() => {
    const bounds = getMonthBounds(value);
    if (bounds?.firstDay !== undefined && bounds?.lastDay !== undefined) {
      scrollToDate(value);
      if (bounds.firstDay !== actualDay) {
        setData(d => ({ ...d, isLoading: true }));
        axiosInstance.get(`/event?sortBy=date&dateRanges[date][start]=${bounds.firstDay}&dateRanges[date][end]=${bounds.lastDay}`)
          .then(response => {
            setData({
              isLoading: false,
              data: orderByDate(response.data.data),
            });
            setActualDay(bounds.firstDay);
          })
          .catch(error => {
            console.error('There was an error!', error);
            setData({
              isLoading: false,
              actualFirstBounds: bounds.firstDay,
              data: [],
            });
          });
      }
    }
  }, [value, actualDay]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(3) }}>
      <Typography variant="h3" sx={{ fontWeight: 700 }} color="primary.main_100">Votre Calendrier</Typography>
      <FabCustom aria-label="nouveau rendez-vous" color="primary" onClick={() => navigate('/event/create')}>
        <Add />
      </FabCustom>
      <BoxCustom>
        <CalendarCustom onChange={onChange} value={value} onActiveStartDateChange={onActiveStartDateChange} />
        <Box
          className="overflow-part"
          sx={{
            width: '100%', p: 1, display: 'flex', flexDirection: 'column', gap: 1,
          }}
        >
          {data.isLoading
            ? (<CircularProgress />)
            : data.data.map(element => (
              <Box key={element.todayDate} id={`date-${element.todayDate.replace(/-/g, '')}`} display="flex" flexDirection="column" gap={1}>
                <Box display="flex">
                  <Typography variant="body1" sx={{ fontWeight: 700 }} color="primary.main_100">
                    {formateDate(element.todayDate).day}
                    {' '}
                    {formateDate(element.todayDate).monthName}
                  </Typography>
                  <span>&nbsp;</span>
                  <Typography variant="body1" color="primary.main_100">
                    {formateDate(element.todayDate).dayName}
                  </Typography>
                </Box>
                {element.events.map(_event => (
                  <EventCard key={_event.id} event={_event} dateFormat="HH:mm" />
                ))}
              </Box>
            ))}
        </Box>
      </BoxCustom>
    </Box>
  );
}

export default Event;
