import React, { createContext, useContext, useState } from 'react';

// Création du contexte
const NavigationBarContext = createContext();

// Composant provider
export function NavigationBarProvider({ children }) {
  const [title, setTitle] = useState('');
  const [isVisible, setIsVisible] = useState(false);

  const handleGoBack = null;

  // Les valeurs et fonctions à fournir à travers le contexte
  const value = {
    title,
    setTitle,
    isVisible,
    setIsVisible,
    handleGoBack,
  };

  return (
    <NavigationBarContext.Provider value={value}>
      {children}
    </NavigationBarContext.Provider>
  );
}

// Hook personnalisé pour utiliser le contexte
export function useNavigationBar() {
  return useContext(NavigationBarContext);
}
