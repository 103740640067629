import { createTheme } from '@mui/material';

export const theme = createTheme({
  palette: {
    primary: {
      main_100: '#28005c',
      main_200: '#3f0985',
      main_300: '#5918ad',
      main_400: '#762dd6',
      main: '#9747ff',
      main_600: '#aa69ff',
      main_700: '#bd8aff',
      main_800: '#cfabff',
      main_900: '#e2ccff',
    },
    secondary: {
      main_100: '#00205c',
      main_200: '#0d3581',
      main_300: '#2150a7',
      main_400: '#3c6fcd',
      main: '#5f93f2',
      main_600: '#79a4f5',
      main_700: '#94b7f9',
      main_800: '#b0cafc',
      main_900: '#ccdeff',
    },
    blue_secondary: {
      main_100: '#00145c',
      main_200: '#06195e',
      main_300: '#0c1e60',
      main_400: '#132462',
      main: '#1a2a63',
      main_600: '#37498a',
      main_700: '#5e70b1',
      main_800: '#909fd8',
      main_900: '#ccd7ff',
    },
    neutral: {
      main_000: '#ffffff',
      main_100: '#f8fbfd',
      main_200: '#f2f4f9',
      main_300: '#e6e9f0',
      main_400: '#d4d8e2',
      main: '#a4aebc',
      main_600: '#757e8f',
      main_700: '#596173',
      main_800: '#40485d',
      main_900: '#1e2840',
    },
  },
  typography: {
    fontFamily: 'Poppins',
    h1: {
      fontSize: 34,
    },
    h2: {
      fontSize: 28,
    },
    h3: {
      fontSize: 24,
    },
    h4: {
      fontSize: 14,
    },
    subtitle1: {
      fontSize: 20,
    },
    subtitle2: {
      fontSize: 18,
    },
    body1: {
      fontSize: 14,
    },
    body2: {
      fontSize: 13,
    },
    caption: {
      fontSize: 12,
    },
  },
});
