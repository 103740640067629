import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import {
  AppBar, Avatar, Button, Toolbar, Typography,
} from '@mui/material';
import { Box, styled, useTheme } from '@mui/system';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const AppBarCustom = styled(AppBar)(({ theme }) => `
  padding-top: ${theme.spacing(1)};
  padding-bottom: ${theme.spacing(1)};
  box-shadow: 0px 2px 4px 0px rgba(26, 42, 99, 0.10);
`);

function ConversationsDetails() {
  const navigate = useNavigate();
  const theme = useTheme();
  const handleGoBack = () => {
    navigate(-1);
  };
  return (
    <Box>
      <AppBarCustom color="transparent">
        <Toolbar sx={{ display: 'flex', justifyContent: 'center' }}>
          <Box sx={{ position: 'absolute', left: theme.spacing(2) }}>
            <Button variant="text" startIcon={<KeyboardArrowLeftIcon />} onClick={handleGoBack} color="primary">Retour</Button>
          </Box>
          <Box sx={{
            display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
          }}
          >
            <Avatar alt="Michel" src="/path/to/avatar.jpg" />
            <Typography variant="subtitle2" color="secondary.main_100">Michel</Typography>
          </Box>
        </Toolbar>
      </AppBarCustom>
    </Box>
  );
}

export default ConversationsDetails;
