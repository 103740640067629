import {Button, Card} from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ListUserItem from '../../components/ListUserItem';

function Profil() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentUser = useSelector(state => state.user.currentUser);
  const handleLogout = async () => {
    dispatch({ type: 'SIGNOUT_REQUEST' });
    navigate('/login');
  };
  return (
    <>
      <div>Profil</div>
      <Button variant="text" onClick={handleLogout} color="primary">Déconnexion</Button>
      <Card>
        <ListUserItem user={currentUser} />
      </Card>
    </>
  );
}

export default Profil;
