import { JitsiMeeting } from '@jitsi/react-sdk';
import React from 'react';

function Meet({
  conferenceSubject, roomName, userName, exit,
}) {
  const jitsiServerUrl = process.env.REACT_APP_JITSI_URL;

  const handleApiReady = externalApi => {
    // Attacher un écouteur pour l'événement videoConferenceLeft
    externalApi.addEventListener('videoConferenceLeft', () => {
      console.log('La réunion a été quittée');
      exit();
      // Ici, vous pouvez ajouter votre logique pour ce qui doit se passer après avoir quitté la réunion
    });

    // Attacher un écouteur pour l'événement readyToClose
    externalApi.addEventListener('readyToClose', () => {
      console.log('Prêt à fermer la fenêtre de la réunion');
      // Ici, vous pouvez ajouter votre logique pour ce qui doit se passer juste avant de fermer la réunion
    });
  };

  return (
    <JitsiMeeting
      domain={jitsiServerUrl}
      roomName={roomName}
      configOverwrite={{
        startWithAudioMuted: false,
        disableModeratorIndicator: true,
        startScreenSharing: true,
        enableEmailInStats: false,
        prejoinConfig: {
          // enabled: false
          hideExtraJoinButtons: ['no-audio', 'by-phone'],
        },
        readOnlyName: true,
        deeplinking: {
          disabled: true,
        },
        subject: conferenceSubject,
        disableInviteFunctions: true,
        toolbarButtons: [
          'camera',
          'chat',
          'closedcaptions',
          'desktop',
          'download',
          'etherpad',
          'feedback',
          'filmstrip',
          'fullscreen',
          'hangup',
          'help',
          'highlight',
          'linktosalesforce',
          'livestreaming',
          'microphone',
          'noisesuppression',
          'participants-pane',
          'profile',
          'raisehand',
          'security',
          'select-background',
          'settings',
          'shareaudio',
          'sharedvideo',
          'shortcuts',
          'stats',
          'tileview',
          'toggle-camera',
          'videoquality',
          'whiteboard',
        ],
      }}
      interfaceConfigOverwrite={{
        DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
      }}
      userInfo={{
        displayName: userName,
      }}
      onApiReady={handleApiReady}
      getIFrameRef={iframeRef => { iframeRef.style.height = '70vh'; iframeRef.style.width = '100%'; }}
    />
  );
}

export default Meet;
