import React from 'react';
import {
  Box, Card, CardActionArea, CardContent, Chip, Typography,
} from '@mui/material';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { useNavigate } from 'react-router-dom';
import { EVENT_TYPE_LIST } from '../const';
import { ellipsis } from '../help';

function EventCard({ event, dateFormat = 'dd/MM/yyyy HH:mm' }) {
  const navigate = useNavigate();
  const date = new Date(event.date);
  const dayFormatted = format(date, dateFormat, { locale: fr });
  const typeLabel = EVENT_TYPE_LIST.find(eventType => eventType.id === event.type)?.label ?? 'Réunion';
  let actors = [];
  if (event.coach) {
    actors.push([event.coach.first_name, event.coach.last_name].join(' '));
  }
  const students = event.students.map(student => [student.first_name, student.last_name].join(' '));
  actors = [...actors, ...students];
  return (
    <Card>
      <CardActionArea onClick={() => navigate(`/event/${event.id}`)}>
        <CardContent>
          <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignContent: 'center',
            alignItems: 'center',
          }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant="body1" color="primary.main_100" sx={{ fontWeight: 700 }}>{event.title}</Typography>
              <Typography variant="body1" color="secondary.main">{ellipsis(actors.join(', '), 30)}</Typography>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
              <Typography variant="body2" color="primary.main_100">{dayFormatted}</Typography>
              <Chip label={typeLabel} size="small" />
            </Box>
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

export default EventCard;
