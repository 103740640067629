import { CircularProgress, Typography } from '@mui/material';
import { Box, useTheme } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { useSelector } from 'react-redux';
import EventCard from '../components/EventCard';
import axiosInstance from '../axiosConfig';

function Home() {
  const theme = useTheme();
  const formattedDate = format(new Date(), 'EEEE dd MMMM', { locale: fr });
  const formattedDateWithCapitalizedFirstLetter = formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);

  const userCurrent = useSelector(state => state.user.currentUser);

  const [events, setEvents] = useState({
    isLoading: true,
    data: [],
  });

  useEffect(() => {
    axiosInstance.get('/event?sortBy=date&limit=2')
      .then(response => {
        setEvents({
          isLoading: false,
          data: response.data.data,
        });
      })
      .catch(error => {
        console.error('There was an error!', error);
        setEvents({
          isLoading: false,
          data: [],
        });
      });
  }, []);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(3) }}>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Typography variant="body2" sx={{ fontWeight: 700 }} color="secondary.main_600">
          {' '}
          {formattedDateWithCapitalizedFirstLetter}
        </Typography>
        <Typography variant="h2" sx={{ fontWeight: 700 }} color="primary.main_100">
          Bonjour
          {' '}
          {userCurrent.first_name}
          ,
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(1) }}>
        <Typography variant="subtitle1" sx={{ fontWeight: 700 }} color="primary.main_100">
          Vos prochains évènements
        </Typography>
        {events.isLoading
          ? (<CircularProgress />)
          : events.data.map(element => (
            <EventCard key={element.id} event={element} />
          ))}
      </Box>
    </Box>
  );
}

export default Home;
