import { loginSuccess } from '../reducer/userSlice';
import axiosInstance from '../../axiosConfig';

export const loginAction = (email, password) => async dispatch => {
  try {
    const response = await axiosInstance.post('/auth/login', { email, password });
    dispatch(loginSuccess(response.data));
  } catch (error) {
    return Promise.reject(error.response?.data?.message || "Une erreur s'est produite");
  }
};
