import React, { useEffect } from 'react';
import { Field, Form, Formik } from 'formik';
import {
  TextField, Button, Typography, Box,
} from '@mui/material';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import { loginAction } from '../../store/action/userAction';
import logo from '../../assets/logo.png';
import { useSnackbar } from '../../Context/Snackbar';

const Content = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignContent: 'center',
  alignItems: 'center',
  background: 'linear-gradient(141deg, #1A2A63 10.82%, #9747FF 144.48%)',
  minHeight: '100vh',
}));

const Row = styled(Box)(() => ({
  height: 0.5,
  backgroundColor: 'white',
  width: '100%',
}));

const LoginButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main_100,
  color: theme.palette.neutral.main_000,
  '&:hover': {
    backgroundColor: theme.palette.primary.main_200,
  },
}));

const TextFieldWhite = styled(TextField)(({ theme }) => ({
  backgroundColor: 'white',
  '& .MuiFilledInput-root': {
    color: theme.palette.secondary.main_300, // Texte noir
  },
}));

function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { openSnackbar } = useSnackbar();

  const userCurrent = useSelector(state => state.user.currentUser);

  const handleLogin = async (email, password) => {
    try {
      await dispatch(loginAction(email, password));
      navigate('/');
    } catch (err) {
      openSnackbar(err || 'Une erreur est survenue', 'error');
    }
  };

  useEffect(() => {
    if (userCurrent) navigate('/');
  });

  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Email invalide').required('Requis'),
    password: Yup.string().required('Requis'),
  });

  return (
    <Content>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        width="100%"
        maxWidth={600}
        gap={6}
        marginX={3}
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          gap={2}
        >
          <Typography variant="h2" color="white">
            Bienvenue sur
          </Typography>
          <img
            src={logo}
            alt="logo"
            loading="lazy"
            height={30}
            width={150}
          />
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          width="100%"
          gap={2}
        >
          <Formik
            initialValues={{ email: '', password: '' }}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
              handleLogin(values.email, values.password);
              setSubmitting(false);
            }}
          >
            {({ errors, touched, isSubmitting }) => (
              <Box width="100%">
                <Form>
                  <Box display="flex" flexDirection="column" width="100%" gap={2}>
                    <Box display="flex" flexDirection="column" width="100%" gap={1}>
                      <Field
                        as={TextFieldWhite}
                        label="Identifiant"
                        name="email"
                        variant="filled"
                        error={touched.email && !!errors.email}
                        helperText={touched.email && errors.email}
                      />
                      <Field
                        as={TextFieldWhite}
                        type="password"
                        label="Mot de passe"
                        name="password"
                        variant="filled"
                        error={touched.password && !!errors.password}
                        helperText={touched.password && errors.password}
                      />
                    </Box>
                    <LoginButton
                      type="submit"
                      variant="contained"
                      fullWidth
                      disabled={isSubmitting}
                    >
                      Se connecter
                    </LoginButton>
                  </Box>
                </Form>
              </Box>
            )}
          </Formik>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="100%"
          >
            <Row />
            <Box marginX={2}>
              <Typography variant="body2" color="white">
                ou
              </Typography>
            </Box>
            <Row />
          </Box>
          <Box marginTop={-1}>
            <Button variant="text" size="small" color="neutral">
              Mot de passe oublié ?
            </Button>
          </Box>
        </Box>
      </Box>
    </Content>
  );
}

export default Login;
