import {
  Avatar, ListItem, ListItemAvatar, ListItemText,
} from '@mui/material';
import React from 'react';

function ListUserItem({ user }) {
  const getUserRole = () => {
    switch (true) {
      case user.roles.includes('COACH'):
        return 'Coach';
      case user.roles.includes('USER'):
        return 'Étudiant';
      default:
        return 'Étudiant';
    }
  };
  return (
    <ListItem
      alignItems="flex-start"
    >
      <ListItemAvatar>
        <Avatar alt={[user.first_name, user.last_name].join(' ')} src={user.image} />
      </ListItemAvatar>
      <ListItemText
        primary={[user.first_name, user.last_name].join(' ')}
        secondary={getUserRole()}
      />
    </ListItem>
  );
}

export default ListUserItem;
