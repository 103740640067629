import {
  Box, Button, Chip, CircularProgress, Divider, List, Typography, useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { useSelector } from 'react-redux';
import { EVENT_TYPE_LIST } from '../../../const';
import Meet from '../../../components/Meet';
import axiosInstance from '../../../axiosConfig';
import ListUserItem from '../../../components/ListUserItem';
import { useNavigationBar } from '../../../Context/NavigationBarContext';

function EventDetails() {
  const { id } = useParams();
  const [event, setEvent] = useState({
    isLoading: true,
    data: null,
  });
  const [joinRoom, setJoinRoom] = useState(false);
  const theme = useTheme();
  const userCurrent = useSelector(state => state.user.currentUser);

  const dayFormatted = event.data ? format(new Date(event.data.date), 'dd/MM/yyyy HH:mm', { locale: fr }) : '';
  const typeLabel = event.data ? EVENT_TYPE_LIST.find(eventType => eventType.id === event.data.type)?.label ?? 'Réunion' : '';

  const { setTitle, setIsVisible } = useNavigationBar();
  useEffect(() => {
    setTitle('Évènement');

    setIsVisible(true);

    return () => {
      setIsVisible(false);
      setTitle('');
    };
  }, [setTitle, setIsVisible]);

  const handleJoinRoom = () => {
    setJoinRoom(!joinRoom);
  };

  const getUsers = data => {
    let users = [];
    if (data.coach) {
      users.push(data.coach);
    }
    users = [...users, ...data.students.map(element => element)];
    return users;
  };
  const users = event.data ? getUsers(event.data) : [];

  useEffect(() => {
    axiosInstance.get(`/event/${id}`)
      .then(response => {
        setEvent({
          isLoading: false,
          data: response.data || null,
        });
      })
      .catch(error => {
        console.error('There was an error!', error);
        setEvent({
          isLoading: false,
          data: null,
        });
      });
  }, [id]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(3) }}>
      {
        event.isLoading
          ? (<CircularProgress />)
          : event.data !== null
            ? (
              <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: theme.spacing(3),
                alignItems: 'flex-start',
              }}
              >
                {
                  event.data?.room_id !== ''
                  && event.data?.room_id !== undefined
                  && event.data?.room_id !== null
                    ? (
                      <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        width: '100%',
                      }}
                      >
                        <Typography variant="subtitle1" sx={{ fontWeight: 700 }} color="primary.main_100">
                          Visio
                        </Typography>
                        {
                      !joinRoom ? (
                        <Button variant="outlined" onClick={() => handleJoinRoom()} color="primary">
                          Rejoindre
                        </Button>
                      ) : (
                        <Box sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: theme.spacing(1),
                          alignItems: 'flex-start',
                          width: '100%',
                        }}
                        >
                          <Button variant="outlined" onClick={() => handleJoinRoom()} color="primary">
                            Quitter
                          </Button>
                          <Meet conferenceSubject={event.data.title} roomName={`room-${event.data.room_id}`} userName={userCurrent.first_name} exit={() => handleJoinRoom()} />
                        </Box>
                      )
                    }
                      </Box>
                    ) : null
                }
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                  <Typography variant="subtitle1" sx={{ fontWeight: 700 }} color="primary.main_100">{event.data.title}</Typography>
                  <Typography variant="body1" color="primary.main_100">{dayFormatted}</Typography>
                  {event.data.address ? (
                    <Typography variant="body1" color="primary.main_100">{event.data.address}</Typography>
                  ) : null}
                  <Chip label={typeLabel} size="small" />
                </Box>
                {event.data.description !== '' ? (
                  <Typography variant="body1" color="primary.main_100">
                    {event.data.description}
                  </Typography>
                ) : null}
                {users.length > 0 ? (
                  <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                    <Typography variant="subtitle1" sx={{ fontWeight: 700 }} color="primary.main_100">
                      Participants (
                      {users.length}
                      )
                    </Typography>
                    <List sx={{ width: '100%' }}>
                      {users.map((person => (
                        <React.Fragment key={person.id}>
                          <ListUserItem user={person} />
                          <Divider />
                        </React.Fragment>
                      )))}
                    </List>
                  </Box>
                ) : null}
              </Box>
            )
            : null
      }
    </Box>
  );
}

export default EventDetails;
