export const EVENT_TYPE = {
  MEET: { id: 1, label: 'Réunion' },
  COMPANY: { id: 2, label: 'Entreprise' },
  WORKSHOP: { id: 3, label: 'Atelier' },
};

export const EVENT_TYPE_LIST = [
  EVENT_TYPE.MEET,
  EVENT_TYPE.COMPANY,
  EVENT_TYPE.WORKSHOP,
];

export const JOB_TYPE = {
  JOB1: { id: 1, label: 'Job1' },
  JOB2: { id: 2, label: 'Job2' },
  JOB3: { id: 3, label: 'Job3' },
};

export const JOB_TYPE_LIST = [
  JOB_TYPE.JOB1,
  JOB_TYPE.JOB2,
  JOB_TYPE.JOB3,
];

export const AVAILABILITY_STATUS = {
  AVAILABLE: { id: 1, label: 'Disponible' },
  BOOKED: { id: 2, label: 'Réservé' },
};

export const AVAILABILITY_STATUS_LIST = [
  AVAILABILITY_STATUS.AVAILABLE,
  AVAILABILITY_STATUS.BOOKED,
];
