import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom';
import React from 'react';
import { ThemeProvider, responsiveFontSizes } from '@mui/material';
import { Provider } from 'react-redux';
import Profil from './views/Profil';
import Search from './views/Search';
import PageLayout from './Layouts/PageLayout';
import { theme } from './theme/theme';
import Home from './views/Home';
import Event from './views/Event';
import EventDetails from './views/Event/EventDetails';
import Conversation from './views/Conversation';
import ConversationsDetails from './views/Conversation/ConversationDetails';
import { store } from './store/store';
import DefaultLayout from './Layouts/DefaultLayout';
import Login from './views/Login';
import { SnackbarProvider } from './Context/Snackbar';
import NewEvent from './views/Event/NewEvent';
import Coach from './views/Coach';
import CoachAppointment from './views/Coach/CoachAppointment';

function RequireAuth() {
  const location = useLocation();
  const isAuthenticated = localStorage.getItem('accessToken');

  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return <Outlet />;
}

function NoAuth() {
  const location = useLocation();
  const isAuthenticated = localStorage.getItem('accessToken');

  if (isAuthenticated) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return <Outlet />;
}

function App() {
  const globalTheme = responsiveFontSizes(theme);

  return (
    <React.StrictMode>
      <Provider store={store}>
        <SnackbarProvider>
          <ThemeProvider theme={globalTheme}>
            <BrowserRouter>
              <Routes>

                <Route element={<PageLayout />}>
                  <Route element={<RequireAuth />}>
                    <Route path="" element={<Home />} />
                    <Route path="search" element={<Search />} />
                    <Route path="event" element={<Event />} />
                    <Route path="event/create" element={<NewEvent />} />
                    <Route path="event/:id" element={<EventDetails />} />
                    <Route path="coach/:id" element={<Coach />} />
                    <Route path="coach/:id/appointment" element={<CoachAppointment />} />
                    <Route path="conversation" element={<Conversation />} />
                    <Route path="conversation/:id" element={<ConversationsDetails />} />
                    <Route path="profil" element={<Profil />} />
                  </Route>
                </Route>

                <Route element={<DefaultLayout />}>
                  <Route element={<NoAuth />}>
                    <Route path="login" element={<Login />} />
                  </Route>
                </Route>

              </Routes>
            </BrowserRouter>
          </ThemeProvider>
        </SnackbarProvider>
      </Provider>
    </React.StrictMode>
  );
}

export default App;
