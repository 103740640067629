import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Avatar, Box, Button, Card, Chip, CircularProgress, Typography, styled, useTheme,
} from '@mui/material';
import axiosInstance from '../../axiosConfig';
import { useNavigationBar } from '../../Context/NavigationBarContext';
import { JOB_TYPE_LIST } from '../../const';

const BoxCustom = styled(Box)(({ theme }) => `
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${theme.spacing(2)};

  height: calc(100vh - 150px);
  
  ${theme.breakpoints.down('md')} {
    height: calc(100vh - 200px);
  }

  .overflow-part {
    height: 100%;
    overflow-y: auto;
  }
`);

function Coach() {
  const { id } = useParams();
  const [coach, setCoach] = useState({
    isLoading: true,
    data: null,
  });
  const { setTitle, setIsVisible } = useNavigationBar();
  const theme = useTheme();
  const navigate = useNavigate();

  useEffect(() => {
    if (coach.data !== null) {
      setTitle([coach.data.first_name, coach.data.last_name].join(' '));
    }

    setIsVisible(true);

    return () => {
      setIsVisible(false);
      setTitle('');
    };
  }, [setTitle, setIsVisible, coach]);

  useEffect(() => {
    axiosInstance.get(`/user/${id}`)
      .then(response => {
        setCoach({
          isLoading: false,
          data: response.data || null,
        });
      })
      .catch(error => {
        console.error('There was an error!', error);
        setCoach({
          isLoading: false,
          data: null,
        });
      });
  }, [id]);
  return (
    <Box>
      <BoxCustom>
        <Box
          className="overflow-part"
          sx={{
            width: '100%', p: 1, display: 'flex', flexDirection: 'column', gap: 3,
          }}
        >
          {
        coach.isLoading
          ? (<CircularProgress />)
          : (
            <Box display="flex" flexDirection="column" gap={2}>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                gap={1}
                sx={{ backgroundColor: theme.palette.secondary.main_100, p: 4 }}
              >
                <Avatar
                  alt={[coach.data.first_name, coach.data.last_name].join(' ')}
                  src={coach.data.image}
                  sx={{ width: 78, height: 78 }}
                />
                <Box display="flex" flexDirection="column" alignItems="center">
                  <Typography variant="h4" color="white">
                    {[coach.data.first_name, coach.data.last_name].join(' ')}
                  </Typography>
                  {
                  JOB_TYPE_LIST.filter(element => coach.data.jobs.includes(element.id.toString())).map(job => (
                    <Typography variant="body2" color="white">
                      {job.label}
                    </Typography>
                  ))
                }
                </Box>
              </Box>
              <Box display="flex" justifyContent="center" sx={{ marginTop: '-34px' }}>
                <Button variant="contained" onClick={() => navigate(`/coach/${id}/appointment`)} color="secondary" sx={{ width: '80%' }}>
                  Prendre rendez-vous
                </Button>
              </Box>
              <Card sx={{ p: 2 }}>
                <Box display="flex" flexDirection="column" gap={0.5}>
                  <Typography variant="h4" color="secondary.main_100">
                    Informations
                  </Typography>
                  <Typography variant="body2">
                    Pariatur commodo laborum deserunt nostrud excepteur elit dolor duis magna ex enim magna qui. Laborum dolore ullamco consectetur aliquip consequat eiusmod culpa mollit. Sit quis exercitation velit voluptate laborum nostrud consequat officia occaecat ex in mollit. Consectetur commodo adipisicing incididunt laboris proident tempor adipisicing ut pariatur quis. Officia esse sint ullamco mollit duis nisi eu veniam est. Sunt amet do irure sunt eiusmod elit adipisicing laborum eiusmod non nulla fugiat. Deserunt eu pariatur elit nostrud in.
                  </Typography>
                </Box>
              </Card>
              <Card sx={{ p: 2 }}>
                <Box display="flex" flexDirection="column" gap={0.5}>
                  <Typography variant="h4" color="secondary.main_100">
                    Spécialités
                  </Typography>
                  <Box display="flex" flexDirection="row" flexWrap="wrap" gap={1}>
                    {JOB_TYPE_LIST.map((job, index) => (
                      <Chip
                        key={job.id}
                        label={`Spec${index}`}
                      />
                    ))}
                  </Box>
                </Box>
              </Card>
            </Box>
          )
      }
        </Box>
      </BoxCustom>
    </Box>
  );
}

export default Coach;
