import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    currentUser: null,
  },
  reducers: {
    loginSuccess: (state, action) => {
      state.currentUser = action.payload.data;
      localStorage.setItem('accessToken', action.payload.access_token);
      localStorage.setItem('refreshToken', action.payload.refresh_token);
    },
  },
});

export const { loginSuccess } = userSlice.actions;
export default userSlice.reducer;
