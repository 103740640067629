export function ellipsis(text, maxLength) {
  if (text.length > maxLength) {
    return `${text.substring(0, maxLength - 3)}...`;
  }
  return text;
}

export function formateDate(dateISO) {
  // Créer un objet Date à partir de la chaîne ISO
  const date = new Date(dateISO);

  // Tableaux pour les jours de la semaine et les mois en français
  const days = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];
  const months = ['janv.', 'févr.', 'mars', 'avr.', 'mai', 'juin', 'juil.', 'août', 'sept.', 'oct.', 'nov.', 'déc.'];

  // Récupérer le jour, le mois et le jour de la semaine
  const day = date.getDate();
  const monthName = months[date.getMonth()];
  const dayName = days[date.getDay()];

  // Formater la date
  return {
    day,
    monthName,
    dayName,
  };
}

export function getMonthBounds(dateString) {
  // Analyser la date d'entrée
  const date = new Date(dateString);

  // Premier jour du mois
  const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);

  // Dernier jour du mois
  const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

  // Fonction d'aide pour formater la date en YYYY-MM-DD
  function transformDate(d) {
    let month = `${d.getMonth() + 1}`;
    let day = `${d.getDate()}`;
    const year = d.getFullYear();

    if (month.length < 2) month = `0${month}`;
    if (day.length < 2) day = `0${day}`;

    return [year, month, day].join('-');
  }

  const firstDayString = transformDate(firstDay);
  const lastDayString = transformDate(lastDay);

  return { firstDay: firstDayString, lastDay: lastDayString };
}
