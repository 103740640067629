import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Accordion, AccordionDetails, AccordionSummary, Box, Button, CircularProgress, Modal, Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useSelector } from 'react-redux';
import LoadingButton from '@mui/lab/LoadingButton';
import { useNavigationBar } from '../../Context/NavigationBarContext';
import axiosInstance from '../../axiosConfig';
import { formateDate } from '../../help';
import { AVAILABILITY_STATUS, EVENT_TYPE } from '../../const';

function CoachAppointment() {
  const { id } = useParams();
  const userCurrent = useSelector(state => state.user.currentUser);
  const navigate = useNavigate();
  const { setTitle, setIsVisible } = useNavigationBar();
  const [coach, setCoach] = useState({
    isLoading: true,
    data: null,
  });

  useEffect(() => {
    setTitle('Prendre rendez-vous');

    setIsVisible(true);

    return () => {
      setIsVisible(false);
      setTitle('');
    };
  }, [setTitle, setIsVisible, coach]);

  useEffect(() => {
    axiosInstance.get(`/user/${id}`)
      .then(response => {
        setCoach({
          isLoading: false,
          data: response.data || null,
        });
      })
      .catch(error => {
        console.error('There was an error!', error);
        setCoach({
          isLoading: false,
          data: null,
        });
      });
  }, [id]);

  const filterAvailabilities = availabilities => availabilities.filter(availabilitie => availabilitie.status === AVAILABILITY_STATUS.AVAILABLE.id);

  const orderByDate = data => {
    const groupedByDate = {};

    data.forEach(availabilitie => {
      const availabilitieDate = availabilitie.date.split('T')[0];

      if (!groupedByDate[availabilitieDate]) {
        groupedByDate[availabilitieDate] = [];
      }

      groupedByDate[availabilitieDate].push(availabilitie);
    });

    // Convertir en format de liste souhaité
    const resultList = Object.keys(groupedByDate).map(date => ({ todayDate: date, availabilities: groupedByDate[date] }));

    return resultList.filter(element => filterAvailabilities(element.availabilities).length > 0);
  };

  const [selectedDate, setSelectedDate] = useState('');
  const [availabilitieId, setAvailaibitieId] = useState(null);
  const [open, setOpen] = useState(false);
  const [isLoadingCreate, setIsLoadingCreate] = useState(false);

  const formatDate = date => new Intl.DateTimeFormat('fr-FR', {
    weekday: 'long', // Jour de la semaine
    day: 'numeric', // Jour du mois
    month: 'long', // Mois
    year: 'numeric', // Année
    hour: '2-digit', // Heure
    minute: '2-digit', // Minutes
    hour12: false,
  }).format(new Date(date));

  const handleOpen = availabilitie => {
    setSelectedDate(availabilitie.date);
    setAvailaibitieId(availabilitie.id);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const createEvent = () => {
    setIsLoadingCreate(true);
    const body = {
      availabilitieId,
      date: selectedDate,
      type: EVENT_TYPE.MEET.id,
      title: `Rendez-vous avec ${[coach.data.first_name, coach.data.last_name].join(' ')}`,
      address: '',
      description: '',
      coach: coach.data.id,
      students: [userCurrent.id],
    };
    axiosInstance.post('/event', body)
      .then(response => {
        setIsLoadingCreate(false);
        navigate(`/event/${response.data.id}`);
      })
      .catch(error => {
        setIsLoadingCreate(false);
        console.error('There was an error!', error);
      });
  };

  const ModalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '70%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  return (
    <Box display="flex">
      <Modal
        open={open}
        onClose={handleClose}
      >
        <Box display="flex" flexDirection="column" gap={2} sx={ModalStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Confirmation de rendez-vous
          </Typography>
          <Typography id="modal-modal-description">
            Voulez-vous valider la prise du rendez-vous le
            {' '}
            {selectedDate !== '' ? formatDate(selectedDate) : ''}
            {' '}
            ?
          </Typography>
          <Box display="flex" gap={1}>
            <LoadingButton
              loading={isLoadingCreate}
              onClick={() => createEvent()}
              variant="contained"
            >
              Valider
            </LoadingButton>
            <Button onClick={handleClose} variant="outlined">Fermer</Button>
          </Box>
        </Box>
      </Modal>
      {
        coach.isLoading
          ? (<CircularProgress />)
          : (
            orderByDate(coach.data.availabilities).length > 0
              ? (
                <Box width="100%" display="flex" flexDirection="column" gap={1}>
                  {
                  orderByDate(coach.data.availabilities).map(grouped => (
                    <Accordion key={grouped.todayDate} sx={{ width: '100%' }}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                      >
                        <Box display="flex">
                          <Typography variant="body1" sx={{ fontWeight: 700 }} color="primary.main_100">
                            {formateDate(grouped.todayDate).day}
                            {' '}
                            {formateDate(grouped.todayDate).monthName}
                          </Typography>
                          <span>&nbsp;</span>
                          <Typography variant="body1" color="primary.main_100">
                            {formateDate(grouped.todayDate).dayName}
                          </Typography>
                        </Box>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box display="flex" flexWrap="wrap" gap={1}>
                          {
                          grouped.availabilities.map(availabilitie => (
                            <Button
                              variant="contained"
                              onClick={() => handleOpen(availabilitie)}
                              color="secondary"
                              key={availabilitie.id}
                            >
                              {
                                new Intl.DateTimeFormat('fr-FR', {
                                  hour: '2-digit',
                                  minute: '2-digit',
                                  hour12: false,
                                }).format(new Date(availabilitie.date))
                              }
                            </Button>
                          ))
                        }
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                  ))
              }
                </Box>
              )
              : (
                <Typography variant="h4" color="primary">Aucune disponibilité</Typography>
              )

          )
      }
    </Box>
  );
}

export default CoachAppointment;
