import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Box, Button, Card, CardActionArea, Chip, CircularProgress, TextField, Typography, styled, useTheme,
} from '@mui/material';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../../axiosConfig';
import { useNavigationBar } from '../../../Context/NavigationBarContext';
import ListUserItem from '../../../components/ListUserItem';
import { JOB_TYPE_LIST } from '../../../const';

const BoxCustom = styled(Box)(({ theme }) => `
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${theme.spacing(2)};

  height: calc(100vh - 150px);
  
  ${theme.breakpoints.down('md')} {
    height: calc(100vh - 200px);
  }

  .overflow-part {
    height: 100%;
    overflow-y: auto;
  }
`);

function NewEvent() {
  const theme = useTheme();
  const navigate = useNavigate();

  const { setTitle, setIsVisible } = useNavigationBar();

  const userCurrent = useSelector(state => state.user.currentUser);
  const [coachs, setCoachs] = useState({
    isLoading: false,
    data: [],
  });

  useEffect(() => {
    setTitle('Nouveau rendez-vous');

    setIsVisible(true);

    return () => {
      setIsVisible(false);
      setTitle('');
    };
  }, [setTitle, setIsVisible]);

  useEffect(() => {
    setCoachs(d => ({ ...d, isLoading: true }));
    axiosInstance.get('/user?sortBy=last_name&limit=100&roles[value][]=COACH&roles[operator]=contains')
      .then(response => {
        setCoachs({
          isLoading: false,
          data: response.data.data,
        });
      })
      .catch(error => {
        console.error('There was an error!', error);
        setCoachs({
          isLoading: false,
          data: [],
        });
      });
  }, []);

  const getUserRelations = users => users.filter(user => user.relations.find(element => element.id === userCurrent.id) !== undefined);

  const getUserNotRelations = users => users.filter(user => user.relations.find(element => element.id === userCurrent.id) === undefined);

  const validationSchema = Yup.object().shape({
    search: Yup.string(),
  });

  const [selectedJobs, setSelectedJobs] = useState([]);
  const [searchValue, setSearchValue] = useState('');

  const handleJobClick = job => {
    setSelectedJobs(prev => {
      if (prev.includes(job)) {
        return prev.filter(j => j !== job);
      }
      return [...prev, job];
    });
  };

  const performSearch = (searchQuery, jobs) => {
    // Préparation de l'état de chargement
    setCoachs({ ...coachs, isLoading: true });

    // Construction des paramètres de la requête
    let queryParams = '/user?sortBy=last_name&limit=100&roles[value][]=COACH&roles[operator]=contains';

    // Ajout du texte de recherche comme paramètre, si présent
    if (searchQuery) {
      queryParams += `&fullName[value]=${encodeURIComponent(searchQuery)}`;
      queryParams += '&fullName[operator]=icontains';
    }

    // Ajout des jobs sélectionnés comme paramètres, si présents
    if (jobs && jobs.length > 0) {
      jobs.forEach(job => {
        queryParams += `&jobs[value][]=${encodeURIComponent(job)}`;
        queryParams += '&jobs[operator]=contains';
      });
    }

    // Lancement de la requête avec les paramètres
    axiosInstance.get(queryParams)
      .then(response => {
        setCoachs({
          isLoading: false,
          data: response.data.data,
        });
      })
      .catch(error => {
        console.error('There was an error!', error);
        setCoachs({
          isLoading: false,
          data: [],
        });
      });
  };

  useEffect(() => {
    const timerId = setTimeout(() => {
      performSearch(searchValue, selectedJobs);
    }, 500); // Déclenche la recherche après 500ms d'inactivité

    return () => clearTimeout(timerId); // Nettoie le timer si le composant est démonté ou si la valeur change
  }, [searchValue, selectedJobs]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(2) }}>
      <BoxCustom>
        <Box
          className="overflow-part"
          sx={{
            width: '100%', p: 1, display: 'flex', flexDirection: 'column', gap: 3,
          }}
        >
          <Formik
            initialValues={{ search: '' }}
            validationSchema={validationSchema}
            onSubmit={(values, actions) => {
              actions.setSubmitting(false);
            }}
          >
            {({ setFieldValue, handleChange, handleBlur }) => (
              <Form>
                <Box
                  sx={{
                    width: '100%', display: 'flex', flexDirection: 'column', gap: 1,
                  }}
                >
                  <Field
                    as={TextField}
                    name="search"
                    label="Rechercher un professionnel"
                    variant="outlined"
                    fullWidth
                    onChange={e => {
                      handleChange(e);
                      setSearchValue(e.target.value); // Met à jour la valeur de recherche pour déclencher useEffect
                    }}
                    onBlur={handleBlur}
                  />
                  <Box display="flex" flexDirection="row" flexWrap="wrap" gap={1}>
                    {/* Exemple de gestion des Chips */}
                    {JOB_TYPE_LIST.map(job => (
                      <Chip
                        key={job.id}
                        label={job.label}
                        onClick={() => handleJobClick(job.id)}
                        color={selectedJobs.includes(job.id) ? 'primary' : 'default'}
                      />
                    ))}
                  </Box>
                </Box>
              </Form>
            )}
          </Formik>
          {
            coachs.isLoading
              ? (<CircularProgress />)
              : coachs.data.length > 0
                ? (
                  <Box display="flex" flexDirection="column" gap={3}>
                    {
                    getUserRelations(coachs.data).length > 0
                      ? (
                        <Box display="flex" flexDirection="column" gap={1}>
                          <Typography variant="h4" color="secondary" sx={{ textTransform: 'uppercase' }}>
                            Coach que vous connaissez
                          </Typography>
                          {
                            getUserRelations(coachs.data).map(element => (
                              <Card key={element.id}>
                                <CardActionArea onClick={() => navigate(`/coach/${element.id}`)}>
                                  <ListUserItem user={element} />
                                </CardActionArea>
                              </Card>
                            ))
                          }
                        </Box>
                      )
                      : null
                    }
                    {
                    getUserNotRelations(coachs.data).length > 0
                      ? (
                        <Box display="flex" flexDirection="column" gap={1}>
                          <Typography variant="h4" color="secondary" sx={{ textTransform: 'uppercase' }}>
                            Liste des coachs
                          </Typography>
                          {
                            getUserNotRelations(coachs.data).map(element => (
                              <Card key={element.id}>
                                <CardActionArea onClick={() => navigate(`/coach/${element.id}`)}>
                                  <ListUserItem user={element} />
                                </CardActionArea>
                              </Card>
                            ))
                          }
                        </Box>
                      )
                      : null
                      }
                  </Box>
                )
                : <Typography variant="h4" color="primary">Aucun coach</Typography>
          }
        </Box>
      </BoxCustom>
    </Box>
  );
}

export default NewEvent;
