import React from 'react';
import { Box } from '@mui/system';
import { Typography } from '@mui/material';

function Search() {
  return (
    <Box>
      <Typography variant="h3" sx={{ fontWeight: 700 }} color="primary.main_100">Rechercher</Typography>
    </Box>
  );
}

export default Search;
