import { Divider, List, Typography } from '@mui/material';
import { Box, useTheme } from '@mui/system';
import React from 'react';
import ListConversationItem from '../../components/ListConversationItem';

function Conversation() {
  const theme = useTheme();
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(3) }}>
      <Typography variant="h3" sx={{ fontWeight: 700 }} color="primary.main_100">Messagerie</Typography>
      <List sx={{ width: '100%' }}>
        <ListConversationItem conversation={{ id: 1, name: 'test', last_message: { text: 'Bonsoir les amis !', date: new Date() } }} />
        <Divider />
      </List>
    </Box>
  );
}

export default Conversation;
