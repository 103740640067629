import {
  Avatar, ListItemAvatar, ListItemButton, ListItemText, Typography,
} from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { ellipsisText } from '../utils';

function ListConversationItem({ conversation }) {
  const navigate = useNavigate();
  const hourFormatted = format(conversation.last_message.date, 'HH:mm', { locale: fr });
  return (
    <ListItemButton
      alignItems="flex-start"
      onClick={() => navigate(`/conversation/${conversation.id}`)}
    >
      <ListItemAvatar>
        <Avatar alt={conversation.name} src={conversation.image} />
      </ListItemAvatar>
      <ListItemText
        primary="Remy Sharp"
        secondary={ellipsisText(conversation.last_message.text, 100)}
      />
      <Typography variant="body1" color="secondary.main" sx={{ mt: 1 }}>{hourFormatted}</Typography>
    </ListItemButton>
  );
}

export default ListConversationItem;
