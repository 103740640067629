import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
  BottomNavigation, BottomNavigationAction, Box, Drawer, IconButton, List, ListItemButton, ListItemIcon, ListItemText, Paper, useMediaQuery, useTheme,
} from '@mui/material';
import {
  Search as SearchIcon, PlaylistAddCheck, Mail, Person,
} from '@mui/icons-material';
import { styled } from '@mui/system';
import { ReactComponent as HomeIcon } from '../assets/home.svg';
import '../style/Navigation.css';
import AppBarCustom from './AppBarCustom';

function Navigation({ children }) {
  const location = useLocation();
  const navigate = useNavigate();

  const navLinks = [
    { label: 'Rechercher', icon: <SearchIcon />, path: '/search' },
    { label: 'Suivi', icon: <PlaylistAddCheck />, path: '/event' },
    { label: 'Messages', icon: <Mail />, path: '/conversation' },
    { label: 'Profil', icon: <Person />, path: '/profil' },
  ];

  const isActiveLink = linkPath => location.pathname.startsWith(linkPath);

  const _theme = useTheme();
  const isMobile = useMediaQuery(_theme.breakpoints.down('md'));

  const drawerWidth = 240;
  const bottomHeight = 72;

  const BottomNavigationActionCustom = styled(BottomNavigationAction)(({ theme, isactiveprop }) => `
    min-width: 50px;
    padding: 0 4px;
    color: ${theme.palette.neutral.main_100};
    border-bottom: ${isactiveprop === 'on' ? `2px solid ${theme.palette.neutral.main_100}` : 'none'};
  `);

  const BoxGrow = styled(Box)(() => `
    width: 64px;

    @media (min-width: 400px) {
      width: 90px;
    }

  `);

  const MainBottomButton = styled(IconButton)(() => `
    bottom: 24px;
    position: absolute;
    width: 64px;
    height: 64px;
    border: 1px solid var(--Blue-Secondary-Blue-Secondary-100, #00145C);
    background: radial-gradient(97.76% 97.76% at 91% 50%, #9747FF 0%, #00205C 100%);
    box-shadow: 0px 2px 6px 0px rgba(26, 42, 99, 0.24);

    @media (min-width: 400px) {
      bottom: 12px;
      width: 90px;
      height: 90px;
    }

  `);

  return (
    <Box>
      {isMobile ? (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <AppBarCustom />
          <Box
            component="main"
            sx={{ flexGrow: 1, p: 3, height: `calc(100% - ${bottomHeight}px)` }}
          >
            {children}
          </Box>
          <Box
            component="nav"
            sx={{ height: bottomHeight, flexShrink: { sm: 0 } }}
          >
            <Paper
              sx={{
                position: 'fixed', bottom: 0, left: 0, right: 0,
              }}
              elevation={3}
            >
              <BottomNavigation
                showLabels
                sx={{
                  height: bottomHeight,
                  backgroundColor: _theme.palette.secondary.main_100,
                }}
              >
                {navLinks.slice(0, 2).map(link => (
                  <BottomNavigationActionCustom
                    key={link.label}
                    component={Link}
                    to={link.path}
                    label={link.label}
                    icon={link.icon}
                    isactiveprop={isActiveLink(link.path) ? 'on' : 'off'}
                  />
                ))}
                <BoxGrow>
                  <MainBottomButton onClick={() => navigate('/')}>
                    <HomeIcon className="home-icon" />
                  </MainBottomButton>
                </BoxGrow>
                {navLinks.slice(2, 4).map(link => (
                  <BottomNavigationActionCustom
                    key={link.label}
                    component={Link}
                    to={link.path}
                    label={link.label}
                    icon={link.icon}
                    isactiveprop={isActiveLink(link.path) ? 'on' : 'off'}
                  />
                ))}
              </BottomNavigation>
            </Paper>
          </Box>
        </Box>
      ) : (
        <Box sx={{ display: 'flex' }}>
          <Box
            component="nav"
            sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
          >
            <Drawer
              variant="permanent"
              sx={{
                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
              }}
            >
              <List>
                {navLinks.map(link => (
                  <ListItemButton
                    key={link.label}
                    component={Link}
                    to={link.path}
                  >
                    <ListItemIcon>{link.icon}</ListItemIcon>
                    <ListItemText primary={link.label} />
                  </ListItemButton>
                ))}
              </List>
            </Drawer>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', width: { sm: `calc(100% - ${drawerWidth}px)` } }}>
            <AppBarCustom />
            <Box
              component="main"
              sx={{ flexGrow: 1, p: 3 }}
            >
              {children}
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default Navigation;
