import React from 'react';
import { Outlet } from 'react-router-dom';
import Navigation from '../components/Navigation';
import { NavigationBarProvider } from '../Context/NavigationBarContext';

function PageLayout() {
  return (
    <NavigationBarProvider>
      <Navigation>
        <Outlet />
      </Navigation>
    </NavigationBarProvider>
  );
}

export default PageLayout;
