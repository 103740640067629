import React from 'react';
import {
  AppBar, IconButton, Toolbar, Typography, styled,
} from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useNavigationBar } from '../Context/NavigationBarContext';

const AppBarStyled = styled(AppBar)(({ theme }) => `
  padding-top: ${theme.spacing(1)};
  padding-bottom: ${theme.spacing(1)};
  box-shadow: 0px 2px 4px 0px rgba(26, 42, 99, 0.10);
  position: initial;
`);

function AppBarCustom() {
  const { title, handleGoBack, isVisible } = useNavigationBar();
  const navigate = useNavigate();

  const goBack = () => {
    if (handleGoBack) {
      handleGoBack();
    } else {
      navigate(-1);
    }
  };

  if (!isVisible) return null;

  return (
    <AppBarStyled color="transparent">
      <Toolbar sx={{ display: 'flex', gap: 2 }}>
        <IconButton color="secondary" onClick={goBack}>
          <ArrowBack />
        </IconButton>
        <Typography variant="subtitle2" color="secondary.main_100">{title}</Typography>
      </Toolbar>
    </AppBarStyled>
  );
}

export default AppBarCustom;
